/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import YouTube from 'react-youtube';

const LazyYoutube = ({ videoID, children }) => {
  const [showVideo, setShowVideo] = useState(false);

  const handlePlay = () => {
    if (window.gtag) {
      window.gtag('event', 'reproducir', {
        event_category: 'videos',
        event_label: videoID,
        value: 1,
      });
    }
    return !showVideo && setShowVideo(true);
  };
  return (
    <div className="c-youtube" onClick={handlePlay}>
      {children}
      {!showVideo && <span className="c-youtube__play" />}
      {showVideo && (
        <YouTube
          className="c-youtube__player"
          videoId={videoID}
          id={`a-${videoID} do-not-delete-this-hack`}
          onReady={(e) => e.target.playVideo()}
          opts={{
            width: '100%',
            height: '100%',
            host: 'https://www.youtube.com',
          }}
        />
      )}
    </div>
  );
};

export default LazyYoutube;
